import React from 'react';

import TermsCat from '../../assets/terms-cat.png';
import TermsDog from '../../assets/terms-dog.png';

const Step6 = (props) => {

  return (
    <div className={props.step===6?"columns is-centered tab-content active":"columns is-centered tab-content"} id="tab6-content">
			<div className="column is-10 ">
				<div className="columns is-centered">
					<div className="column is-10">
						<div className="card terms-card">
							<div className="card-header has-text-centered">
                <img src={TermsDog} alt="Dog illustration" />
								<p className="has-text-weight-bold is-size-4">Thank you!</p>
								<img src={TermsCat} alt="Cat illustration" />
							</div>
						</div>
						<section className="section">
						<div className="field">
							<p className="has-text-weight-bold">Thank you for purchasing a {props.domain && props.domain.county_site_slogan ? props.domain.county_site_slogan : ""} Pet License.  </p>
						</div>
						<div className="field">
							<p>Your tag will be mailed to you w/in 10 days of verifying your pet’s rabies certification. </p>
						</div>
						<div className="field">
							<p>If you did not upload a certification please mail to the following address:
								<br/> <b>1029 N Stuart St., #300, Arlington, VA 22201</b></p>
						</div>
						{/* <a className="button is-primary" href="/richmond-sort">Back to our website</a> */}
						</section>
					</div>
				</div>
			</div>
		</div>
  );
}

export default Step6;
