import React from 'react';
import {  AuthConsumer } from '../utils/AuthContext';
import LostBanner from '../../assets/lost-banner.png'
import './dashboard.scss'

import DashboardDefault from './dashboardDefault';
import DashboardLogged from './dashboardLogged';

const Dashboard = (props) => {

  return (
    <AuthConsumer>
      {({ isAuth, login, logout, user, county }) => (
        <div>
          <div className="banner" style={{backgroundImage: 'url('+ LostBanner +')'}}>
            <section className="hero">
              <div className="hero-body">
               <div className="container">
                 <p className="is-size-4 has-text-weight-bold has-text-white">Found a pet? Scan the tag here!</p>
                 <p className="has-text-white">Easily alert the owner of the pet's location</p>
               </div>
             </div>
            </section>
          </div>
          {isAuth ?
            <DashboardLogged {...props} /> : <DashboardDefault {...props} />
          }


        </div>
      )}
    </AuthConsumer>
  );
}

export default Dashboard;
