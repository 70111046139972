import React, { useEffect, useState } from "react";
// import Input from '../general/input';
// import Select from '../general/select';
import ActionBar from "../general/actionBar";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import ProfileData from "./profileData";
import ProfilePrivacy from "./profilePrivacy";
import ProfileImage from "./profileImage";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Profile = (props) => {
	const [user, setUser] = useState();
	const [profileImage, setProfileImage] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();

	useEffect(() => {
		getUser();
	}, []);

	console.log(history)

	const getUser = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "user/" + props.user.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				setUser(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteUser = () => {
		axios
			.delete(process.env.REACT_APP_API_URL + "user/" + props.user.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				toast.success("Account deleted!");
        history.push("/")
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.data.message);
			});
	};

	return (
		<div>
			<section className="section">
				<div className="columns">
					<div className="column is-12">
						{user ? (
							<p className="has-text-weight-bold is-size-4">
								{user.first_name}'s information
							</p>
						) : (
							<Skeleton height={40} width={400} />
						)}
					</div>
				</div>

				<div className="columns">
					<div className="column is-3">
						{user ? (
							<ProfileImage
								profileImage={profileImage ? profileImage : user.picture}
								setProfileImage={setProfileImage}
							/>
						) : (
							<Skeleton height={120} width={"100%"} />
						)}
					</div>
					<div className="column is-9">
						{user ? (
							<ProfileData user={user} />
						) : (
							<Skeleton
								count={8}
								height={32}
								width={300}
								className="mt-4 mr-4"
							/>
						)}
					</div>
				</div>
			</section>

			<section className="section">
				<div className="columns">
					<div className="column is-12">
						{user ? (
							<p className="has-text-weight-bold is-size-4">
								{user.first_name}'s privacy settings
							</p>
						) : (
							<Skeleton height={40} width={400} />
						)}
					</div>
				</div>

        <div className="columns">
					<div className="column is-12">
						{user ? (
							<ProfilePrivacy user={user} />
						) : (
							<Skeleton
								count={8}
								height={32}
								width={300}
								className="mt-4 mr-4"
							/>
						)}
					</div>
				</div>
			</section>

			<section className="section">
				<div className="columns">
					<div className="column is-3"></div>
					<div className="column is-9">
						<div className="content">
							<div className="mb-4">
								<span className="tag is-danger is-light is-large has-text-weight-bold">
									Danger zone
								</span>
							</div>
							<div className="columns">
								<div className="column is-4">
									<p>Reset/change your password</p>
									<Link to="/user/resetPassword" className="button is-danger">
										Reset password
									</Link>
								</div>
								<div className="column is-4">
									<p>If you proceed with this action, you cannot reverse it.</p>
									<button
										className="button is-danger"
										onClick={() => setDeleteModal(true)}
									>
										Delete user
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

      {deleteModal ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => setDeleteModal(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head has-background-danger-light ">
							<p className="modal-card-title has-text-danger-dark">Delete account</p>
							<button
								className="delete"
								onClick={() => setDeleteModal(false)}
							></button>
						</header>
						<section className="modal-card-body">
              <p>Deleting your account cannot be reversed and you will lose all the account information. Are you sure you want to proceed?</p>
						</section>
						<footer className="modal-card-foot">
							<button
								className="button is-danger"
								onClick={() => deleteUser()}
							>
								Yes, delete my account
							</button>
							<button
								className="button is"
								onClick={() => setDeleteModal(false)}
							>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Profile;
