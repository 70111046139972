import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./marker";
import axios from "axios";

const Map = (props) => {
	const [center, setCenter] = useState({ lat: 37.5246403, lng: -77.5633018 });
	const [zoom] = useState(9);
	const [markers, setMarkers] = useState();
	useEffect(() => {
		if (props.type === "owners") {
			axios
				.get(process.env.REACT_APP_API_URL + "get/users", {
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				})
				.then((res) => {
					setMarkers(res.data.data);
					console.log(res.data.data);
				})
				.catch((err) => {
					console.log(err);
				});
		} else if (props.type === "petScanned") {
      setMarkers(props.data)
      setCenter({
        lat: parseInt(props.data[0].latitude),
				lng: parseInt(props.data[0].longitude),
      })
		} else if (props.domain && props.domain.county_site_name) {
			// Center map to current county
			setCenter({
				lat: parseInt(props.domain.latitude),
				lng: parseInt(props.domain.longitude),
			});
			axios
				.get(
					process.env.REACT_APP_API_URL +
						"configurations/" +
						props.type +
						"/" +
						props.domain.county_site_name,
					{
						headers: {
							Accept: "application/json",
							Authorization: "Bearer " + localStorage.getItem("token"),
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}
				)
				.then((res) => {
					setMarkers(res.data.data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props]);

	const renderMarkers = markers
		? markers.map((marker, index) => {
				return (
					<Marker
						key={index}
						marker={marker}
						lat={marker.latitude || marker.lat}
						lng={marker.longitude || marker.lng}
						name={marker.name}
					/>
				);
		  })
		: "";

	return (
		<div style={{ height: "100vh", width: "100%" }}>
			<GoogleMapReact
				// bootstrapURLKeys={{ key: 'AIzaSyAUmua4dWmdxe1Yb0PlxyhQ9-zhSJe8l9w' }}
				center={center}
				// onChange={(e)=>console.log(e.marginBounds)}
				defaultZoom={zoom}
			>
				{renderMarkers}
			</GoogleMapReact>
		</div>
	);
};

export default Map;
