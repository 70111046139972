import React from "react";
import { Link } from "react-router-dom";
import PetsListPreview from "./petsListPreview";

const Pets = (props) => {
	return (
		<div>
			<section className="section">
				<PetsListPreview />
				<div className="columns">
					<div className="column is-7">
						<Link to={"/user/pet-new"} className="card is-horizontal is-flex">
							<div className="card-image"></div>
							<div className="card-content">
								<p className="">+ Add new pet</p>
							</div>
						</Link>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Pets;
