import React, { useState } from "react";

const ProfilePrivacy = (props) => {
	const [loading, setLoading] = useState(false);

	const user = props.user;

	// https://api.roamingtails.co/api/v1/settings
	const options = [
		"email",
		"pet_name",
		"first_name",
		"last_name",
		"dob",
		"phone",
		"mobile",
		"type",
		"breed",
		"color",
		"vet_name",
		"vet_number",
		"apt",
		"city",
		"zipcode",
		"state",
		"image",
		"country_code",
		"tag_number",
		"description",
		"vet_address",
		"microchip_id",
		"medical_records",
	];

	const save = () => {
		console.log("save");
	};

	return (
		<div className="columns is-multiline">
			<div className="column is-3 content">
				<p>
					Select the info that you want to display when your pet's tag is
					scanned.
				</p>
				<p>
					This will be public information that in case your pet gets lost, will
					be very important.
				</p>
			</div>
			<div className="column is-9">
				<div className="columns is-multiline">
					{options.map((option, index) => {
						return (
							<div className="column is-4" key={index}>
								<label class="checkbox">
									<input type="checkbox" id={option} />
									{option
										.replace("_", " ")
										.toLowerCase()
										.split(" ")
										.map(
											(word) => word.charAt(0).toUpperCase() + word.substring(1)
										)
										.join(" ")}
								</label>
							</div>
						);
					})}
					<div className="column is-12">
						<button
							className={
								loading ? "button is-success is-loading" : "button is-success"
							}
							onClick={() => save()}
						>
							Update privacy information
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePrivacy;
