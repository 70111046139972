import React from "react";
import Skeleton from "react-loading-skeleton";

import FranklinLogo from "../../assets/franklyn-logo.png";
import ArlingtonLogo from "../../assets/arlington-logo.jpg";
import JamesCityCountyLogo from "../../assets/jamescitycounty-logo.jpg";
import RTLogo from "../../assets/logo-smart.png";

const Logo = (props) => {
	// let hostname = window.location.hostname.split('.')[0];
	// console.log(hostname);

	// let url;
	// let logoAlt;
	// switch (hostname) {
	//   case 'localhost':
	//   url = "https://placeholder.com/wp-content/uploads/2018/10/placeholder-1.png";
	//   logoAlt = 'default placeholder logo'
	//   break;

	//   case "franklincounty":
	//   url = FranklinLogo;
	//   logoAlt = 'Franklin County logo'
	//   break;

	//   case "arlingtoncounty":
	//   url = ArlingtonLogo;
	//   logoAlt = 'Arlington County logo'
	//   break;

	//   case "jamescitycountyva":
	//   url = JamesCityCountyLogo;
	//   logoAlt = 'James City County logo'
	//   break;

	//   default:
	//   url = RTLogo;
	//   logoAlt = 'RoamingTails logo';
	// }

	if (!props.logo) {
		return <Skeleton circle={true} height={40} width={40} />;
	} else {
		return <img src={props.logo} height="40" alt={"logoAlt"} />;
	}
};

export default Logo;
