import React from 'react'
import { Route } from 'react-router-dom'
import { AuthConsumer } from './AuthContext'
import Login from '../account/login';

const ProtectedRoute = ({
  component: Component,
  layout: Layout,
   ...rest
}) => (
  <AuthConsumer>
    {({ isAuth }) => (
      <Route {...rest} render={(props) =>
          <Layout {...props}>
            {isAuth ? <Component {...props} /> : <Login />}
          </Layout>
        }

      />
    )}
  </AuthConsumer>
)

export default ProtectedRoute
