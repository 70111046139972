import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './license-form.scss';

// Form steps
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';

import Puppies from '../../assets/puppies.jpg';

const LicenseRenew = (props) => {

  const [step, setStep] = useState(3);
  const [pets, setPets] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'pets', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
      setPets(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  const submitForm = () => {
    let fname = document.getElementById('fname');
    let lname = document.getElementById('lname');
    let email = document.getElementById('email');
    let street = document.getElementById('street');
    let usState = document.getElementById('state');
    let city = document.getElementById('city');
    let zip = document.getElementById('zip');
    let county = document.getElementById('county');

    let cat = document.getElementById('cat');
    let dog = document.getElementById('dog');
    let male = document.getElementById('male');
    let female = document.getElementById('female');
    let spayed = document.getElementById('spayed');
    let unaltered = document.getElementById('unaltered');
    let price = document.getElementById('total-price');

    let certDate = document.getElementById('certDate');
    let certTime = document.getElementById('certTime');
    let autoRenew = document.getElementById('auto-renew');

    let petName = document.getElementById('petName');
    let petDob = document.getElementById('petDob');
    let petWeight = document.getElementById('petWeight');
    let petColor = document.getElementById('petColor');
    let petBreed = document.getElementById('petBreed');
    let petHair = document.getElementById('petHair');

    var tagNo = "L" + Math.floor(100000 + Math.random() * 900000);
    let licenseData = {
      first_name: fname.value,
      last_name: lname.value,
      email: email.value,
      address1: street.value,
      address2: "A",
      apt: "313",
      city: city.value,
      state: usState.value,
      county: county.value,
      zipcode: zip.value,
      cert_date: certDate.value,
      cert_time: certTime.value,
      // cert: imgUrl,
      auto_renew: autoRenew.checked ? true : false,
      cert_verified: false,
      tag_number: tagNo,
      tag_activated: false,
      pet_type: cat.checked ? "Cat" : "Dog",
      pet_status: spayed.checked ? "Spayed/Neutered" : "Unaltered",
      pet_gender: male.checked ? "Male" : "Female",
      pet_name: petName.value,
      pet_dob: petDob.value,
      pet_weight: petWeight.value,
      pet_breed: petBreed.value,
      pet_color: petColor.value,
      pet_hair: petHair.value
    };

    console.log(licenseData)

    document.querySelector('.submit-button').classList.add('is-loading')
    setTimeout(() => {
      if (step < 6) {
        setStep(step+1);
      }
    }, 300)

    // axios.post(process.env.REACT_APP_API_URL + 'register', licenseData, {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem('token')}  `,
    //   },
    // })
    // .then(function(response) {
    //   console.log(response)
    //   if (step < 6) {
    //     setStep(step+1);
    //   }
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }

  const nextStep = (e) => {
    e.preventDefault();
    if (step < 6) {
      setStep(step+1);
    }
    console.log(step);
  }
  const prevStep = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step-1);
    }
    console.log(step);
  }

  return (
    <div>
      <div className="broward-header" style={{backgroundImage: "url("+Puppies+")"}}>
  			<div className="container">
  				<div className="section has-text-centered">
  					<div className="columns is-centered">
  						<div className="column is-7">
                <p className="has-text-white is-size-5 has-text-weight-bold">Thank you for protecting the safety of your pet by purchasing a {props.county} County Pet License.</p>
  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
      <section className="section">
        <div className="columns is-centered">
					<div className="column is-10">
						<div className="columns is-mobile progress-tabs is-aligned-bottom is-multiline">
							<div className={step+1>3?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="3">
								<p className="is-uppercase is-size-7">Rabies details</p>
								<span></span>
							</div>
							<div className={step+1>4?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="4">
								<p className="is-uppercase is-size-7">Terms & Conditions</p>
								<span></span>
							</div>
							<div className={step+1>5?"column has-text-centered active progress-tab":"column has-text-centered progress-tab"} id="5">
								<p className="is-uppercase is-size-7">Checkout</p>
								<span></span>
							</div>
						</div>
					</div>
				</div>

      <Step3 step={step} pets={pets} />

      <Step4 step={step}/>

      <Step5 step={step}/>

      <Step6 step={step}/>


      <div className="columns is-centered" >
        <div className="column is-10">
          <div className="columns">
            <div className="column is-6">
              <div className="buttons">
                {step > 3  && step < 6 ? 
                  <button onClick={(e)=>prevStep(e)} className="button is-white">Previous</button> : ""
                }

                {step < 5 ? 
                  <button onClick={(e)=>nextStep(e)} className="button is-primary next-button">Next</button> : (step === 5 ? 
                    <button className="button is-primary submit-button" onClick={()=>submitForm()}>Submit</button> : "")
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </div>
  );
}

export default LicenseRenew;
