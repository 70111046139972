import React from "react";
import { Link } from "react-router-dom";
import WelcomeIllustration from "../../assets/welcome-illustration.svg";
import Skeleton from "react-loading-skeleton";
// import FAQIllustration from '../../assets/faq-illustration.svg'

import Faqs from "./faqs";

const DashboardDefault = (props) => {
	return (
		<div>
			<section className="section">
				<div className="columns is-multiline is-vcentered">
					<div className="column is-3">
						<img src={WelcomeIllustration} alt="Person greeting dog" />
					</div>
					<div className="column is-7">
						{!props.domain ? (
							<Skeleton height={36} />
						) : (
							<p className="is-size-4 has-text-weight-bold">
								Welcome{" "}
								{props.domain.county_site_slogan
									? " to " + props.domain.county_site_slogan + "!"
									: "!"}
							</p>
						)}

						<p>What can we help you with?</p>
					</div>
				</div>
			</section>

			<section className="section">
				<div className="columns is-multiline">
					<div className="column is-6">
						<Link className="card" to="/user/license">
							<div className="card-content has-text-centered">
								I want to license my pet
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I want to renew my pet's license
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								Both my pet and I relocated
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I don't have a pet anymore
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I want to transfer my pet to another owner
							</div>
						</Link>
					</div>
				</div>
			</section>

			<Faqs />
		</div>
	);
};

export default DashboardDefault;
