import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Tag from '../general/tag';
import './marker.scss';

const Marker = (props) => {

  const [licensedPet, setLicensedPet] = useState(true);

  const marker = props.marker;

  useEffect(() => {
    if (marker.pets) {
      marker.pets.forEach((pet, index) => {
        if (!pet.tag_number) {
          setLicensedPet(false);
          console.log(pet);
        }
      })
    }
  }, [])

  const displayInfo = (e) => {
    e.stopPropagation();
    const markerInfo = document.querySelectorAll('.marker-info');
    const thisMarkerInfo = e.target.childNodes[0];
    if (markerInfo && thisMarkerInfo && e.target.classList.contains("marker")) {
      markerInfo.forEach(function(el, index) {
        el.classList.remove('is-active');
      });
      thisMarkerInfo.classList.toggle('is-active');
      thisMarkerInfo.closest('.marker').classList.toggle('is-active');
    }
    console.log(e.target);
  }

  const closeInfo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.closest('.marker-info').classList.remove('is-active');
    e.target.closest('.marker').classList.remove('is-active');
  }

  let markerClassName;
  if (!licensedPet) {
    markerClassName = 'marker is-unlicensed';
  } else if (marker.email.includes('org')) {
    markerClassName = 'marker is-dangerous';
  } else {
    markerClassName = 'marker';
  }

  return (
    <div className={markerClassName} title={props.name} onClick={(e)=>displayInfo(e)}>
      <span className="marker-info">
        <div className="content">
          {marker && marker.name ? <p className="has-text-weight-bold">{marker.name}</p> : ""}
          {marker ? <p className="has-text-weight-bold">{marker.first_name} {marker.last_name}</p> : ""}
          {!licensedPet ? <Tag type="warning"><p>Missing license</p></Tag> : ""}
          {marker ? <p>{marker.email}</p> : ""}
          {marker ? <p>{marker.address1}</p> : ""}
          {marker ? <p><a href={marker.website} target="_blank">{marker.website}</a></p> : ""}
          {marker ? <p>Mobile: {marker.mobile}</p> : ""}
          {marker ? <p>Phone: <a href={"tel:"+marker.phone} target="_blank">{marker.phone}</a></p> : ""}
          {marker && marker.pets? <p>No. of pets: {marker.pets.length}</p>: ""}
          
          {marker && marker.pets ? 
            <div className="field">
              {marker.email.includes('org') ? <Tag type="danger"><p>Dangerous pet</p></Tag> : ""}
              {marker.pets.map((pet, index) => {
                return (
                  <Link to={"/admin/pet/"+pet.id} key={index}>
                    {pet.name}
                    {marker.pets.length > 1? ", " : ""}
                  </Link>
                )
              })}
            </div>
          :""}
          <button className="button is-small is-primary" onClick={(e)=>closeInfo(e)}>Close</button>
        </div>
      </span>
    </div>
  );
};

export default Marker;
