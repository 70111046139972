import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

// import Tag from '../general/tag';
import Input from "../general/input";
// import Select from '../general/select';
import Table from "../general/table";
import ActionBar from "../general/actionBar";
import PetProfileImage from "./petProfileImage";

const PetSingle = (props) => {
	const [pet, setPet] = useState();
	const [missing, setMissing] = useState(false);
	const [petProfileImage, setPetProfileImage] = useState();
	const [activateTagModal, setActivateTagModal] = useState(false);
	const [spayed, setSpayed] = useState(false);
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();

	useEffect(() => {
		if (props.pet) {
			setPet(props.pet);
		} else {
      getPet();
		}
	}, []);

	const getPet = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "pets/" + props.match.params.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				setPet(res.data.data);
				setPetProfileImage(res.data.data.image);
				setSpayed(res.data.data.spayed_neutered);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const updatePet = () => {
    setLoading(true);

		let petName = document.getElementById("petName");
		let petSpecies = document.getElementById("petSpecies");
		let petBreed = document.getElementById("petBreed");
		let petHair = document.getElementById("petHair");
		let petColor = document.getElementById("petColor");
		let petDob = document.getElementById("petDob");
		let petGender = document.getElementById("petGender");
		let petSpayed = document.getElementById("petSpayed");
		const petData = {
			id: pet.id,
			name: petName.value,
			type: petSpecies.value,
			// other_type: "",
			dob: petDob.value,
			breed: petBreed.value,
			color: petColor.value,
			// vet_name: "",
			// vet_number: "",
			image: petProfileImage ? petProfileImage : pet.image,
			hair: petHair.value,
			gender: petGender.value,
			spayed_neutered: spayed ? true : false,
			// address1: "",
			// address2: "",
			// apt: "",
			// city: "",
			// zipcode: "",
			// state: "",
			// country_code: "",
			// tag_number: "",
			// description: "",
			// vet_address: "",
			// medical_records: "",
			// crc: "",
			// insurance_name: "",
			// insurance_id: "",
			// microchip_id: "",
			// opt_out_dashboard_img: "",
			// dangerous_pet: "",
		};

		axios
			.post(process.env.REACT_APP_API_URL + "pets/edit", petData, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}  `,
				},
			})
			.then(function (response) {
				toast.success("Pet updated!");
        setLoading(false);
			})
			.catch(function (error) {
        setLoading(false);
        toast.error(error.data.message);
				console.log(error);
			});
	};

  const deletePet = () => {
    axios.delete(process.env.REACT_APP_API_URL + "pets/" + props.match.params.id, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then(function (response) {
      console.log(response);
      toast.success("Pet deleted!");
      history.push("/user/pets")
    })
    .catch(function (error) {
      console.log(error);
    });
  }

	const activateTag = () => {
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"tags/activate/" +
					document.getElementById("tagNumber").value +
					"/" +
					pet.id,
				{
					tagNumber: document.getElementById("tagNumber").value,
					petId: pet.id,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(function (response) {
        if (!response.data.success) { 
          toast.error(response.data.message);
        } else {
          setActivateTagModal(false);
          toast.success("Tag activated!");
          getPet();
        }
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const deactivateTag = () => {
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"tags/deactivate/" +
					pet.tag_number +
					"/" +
					pet.id,
				{
					tagNumber: pet.tag_number,
					petId: pet.id,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const selectRow = (e) => {
		e.target.closest("tr").classList.toggle("is-selected");
	};

	const changeMissing = (e) => {
		if (e.target.value === "Yes") {
			setMissing(true);
		} else setMissing(false);
	};

	return (
		<div>
			<ActionBar
				{...props}
				back={true}
				rightSide={
					<div className="buttons">
						<button
							className="button is-text is-small"
							onClick={() => props.history.goBack()}
						>
							Cancel
						</button>
						<button
							className={loading ? "button is-success is-small is-loading" : "button is-success is-small"}
							onClick={() => updatePet()}
						>
							Save
						</button>
					</div>
				}
			/>

			{missing ? (
				<section className="section">
					<div className="columns">
						<div className="column is-12">
							<div className="card has-background-danger">
								<div className="card-content content">
									<p className="has-text-weight-bold has-text-white">
										{pet ? pet.name : ""} is missing
									</p>
									<p className="has-text-white">
										To help you find him more easily, we generated a Missing
										Poster that can be shared online or printed.{" "}
									</p>
									<button className="button is-white is-small">
										Download Poster
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				""
			)}

			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<p className="has-text-weight-bold is-size-4">
							{pet ? (
								pet.name + "'s information"
							) : (
								<Skeleton height={40} width={400} />
							)}
						</p>
					</div>
				</div>

				<div className="columns">
					<div className="column is-3">
						{petProfileImage || pet ? (
							<PetProfileImage
								petProfileImage={
									petProfileImage ? petProfileImage : pet.profile
								}
								setPetProfileImage={setPetProfileImage}
							/>
						) : (
							<Skeleton height={120} width={"100%"} />
						)}
					</div>
					{pet ? (
						<div className="column is-9">
							<div className="columns is-multiline">
								<div className="column is-4">
									<Input
										label="Name"
										placeholder="Shorty"
										value={pet ? pet.name : ""}
										id="petName"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Species"
										placeholder="Dog"
										value={pet ? pet.type : ""}
										id="petSpecies"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Breed"
										placeholder="Beagle"
										value={pet ? pet.breed : ""}
										id="petBreed"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Hair"
										placeholder="Short"
										value={pet ? pet.hair : ""}
										id="petHair"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Hair color"
										placeholder="Brown and white"
										value={pet ? pet.color : ""}
										id="petColor"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Date of birth"
										placeholder=""
										value={pet ? moment(pet.dob).format("MMM Do YY") : ""}
										id="petDob"
									/>
								</div>
								<div className="column is-4">
									<Input
										label="Gender"
										placeholder="Male"
										value={pet ? pet.gender : ""}
										id="petGender"
									/>
								</div>
								<div className="column is-4">
									{/* Replace with select dropdown -> true/false */}
									<div>
										<label className="label">Spayed/Neutered</label>
										<div className="select is-fullwidth">
											<select
												id="petSpayed"
												defaultValue={spayed ? "1" : "0"}
												onChange={(e) => {
													e.target.value === "1"
														? setSpayed(true)
														: setSpayed(false);
												}} //
											>
												<option value="1">Yes</option>
												<option value="0">Unaltered</option>
											</select>
										</div>
									</div>
									{/* <Input
									label="Spayed/Neutered"
									placeholder="Yes"
									value={pet ? pet.spayed_neutered : ""}
									id="petSpayed"
								/> */}
								</div>
								<div className="column is-4">
									<div>
										<label className="label">Missing</label>
										<div className="field">
											<div className="control">
												<div
													className={
														missing
															? "select is-fullwidth is-danger"
															: "select is-fullwidth"
													}
												>
													<select onChange={(e) => changeMissing(e)}>
														<option>No</option>
														<option value="Yes">Yes</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<Skeleton count={8} height={32} width={300} className="mt-4 mr-4" />
					)}
				</div>
			</section>

			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{pet ? pet.name : ""}'s licenses
						</p>
					</div>
					<div className="level-right">
						<button
							className="button is-dark"
							onClick={() => setActivateTagModal(true)}
						>
							Activate tag
						</button>
					</div>
				</div>
				<Table
					thTitles={["License #", "License Date", "Expiry Date", "Status"]}
				>
					<tr>
						<td nowrap="true">
							<input type="checkbox" onChange={(e) => selectRow(e)}></input>
						</td>
						<td nowrap="true">
							<p>{pet ? pet.tag_number : "No license"}</p>
						</td>
						<td nowrap="true">
							<p>
								{pet && pet.rabiescert
									? moment(pet.rabiescert.cert_date).format("MMM Do YYYY")
									: ""}
							</p>
						</td>
						<td nowrap="true">
							<p>
								{pet && pet.rabiescert
									? moment(pet.rabiescert.cert_date)
											.add(pet.rabiescert.cert_time, "y")
											.format("MMM Do YYYY")
									: ""}
							</p>
						</td>
						<td nowrap="true">
							{pet && pet.tag_number ? (
								<p className="has-text-success">Active</p>
							) : (
								<p className="has-text-danger">Inactive</p>
							)}
						</td>
					</tr>
				</Table>
			</section>

			<section className="section">
				<div className="level">
					<div className="level-left">
						<p className="has-text-weight-bold is-size-4">
							{pet ? pet.name : ""}'s rabies certificates
						</p>
					</div>
					<div className="level-right">
						{/* <button className="button is-dark is-small">+ Add new certificate</button> */}
					</div>
				</div>
				<Table thTitles={["Certificate", "Date", "Expiry Date", "Status"]}>
					<tr>
						<td nowrap="true">
							<input type="checkbox" onChange={(e) => selectRow(e)}></input>
						</td>
						<td nowrap="true">
							{pet && pet.rabiescert ? (
								<a href={pet.rabiescert.certificate} target="_blank">
									Open Certificate
								</a>
							) : (
								<p>N/A</p>
							)}
						</td>
						<td nowrap="true">
							<p>
								{pet && pet.rabiescert
									? moment(pet.rabiescert.date_of_last_rabies).format(
											"MMM Do YYYY"
									  )
									: "N/A"}
							</p>
						</td>
						<td nowrap="true">
							<p>
								{pet && pet.rabiescert
									? moment(pet.rabiescert.expiration_date).format("MMM Do YYYY")
									: "N/A"}
							</p>
						</td>
						<td nowrap="true">
							{pet && pet.rabiescert ? (
								<p className="has-text-success">Active</p>
							) : (
								<p className="has-text-danger">Inactive</p>
							)}
						</td>
					</tr>
				</Table>
			</section>

      <section className="section">
				<div className="columns">
					<div className="column is-12">
						<div className="content">
							<div className="mb-4">
								<span className="tag is-danger is-light is-large has-text-weight-bold">
									Danger zone
								</span>
							</div>
							<div className="columns">
								<div className="column is-4">
									<p>If you proceed with this action, you cannot reverse it.</p>
									<button
										className="button is-danger"
										onClick={() => setDeleteModal(true)}
									>
										Delete pet
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <section className="section">
				<div className="columns">
					<div className="column is-12">
						<p className="is-size-4 has-text-weight-bold">Pet administration</p>
					</div>
				</div>
				<div className="columns is-multiline">
					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I want to renew this pet's license
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								Both my pet and I relocated
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I don't have this pet anymore
							</div>
						</Link>
					</div>

					<div className="column is-6">
						<Link className="card" to="/dashboard/rabies">
							<div className="card-content has-text-centered">
								I want to transfer this pet to another owner
							</div>
						</Link>
					</div>
				</div>
			</section> */}

			{activateTagModal ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => setActivateTagModal(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Activate tag</p>
							<button
								className="delete"
								onClick={() => setActivateTagModal(false)}
							></button>
						</header>
						<section className="modal-card-body">
							<Input
								id="tagNumber"
								label="Tag number"
								placeholder="10-digit number"
							/>
						</section>
						<footer className="modal-card-foot">
							<button
								className="button is-primary"
								onClick={() => activateTag()}
							>
								Activate tag
							</button>
							<button
								className="button is"
								onClick={() => setActivateTagModal(false)}
							>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}

      {deleteModal ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => setDeleteModal(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head has-background-danger-light ">
							<p className="modal-card-title has-text-danger-dark">Delete pet</p>
							<button
								className="delete"
								onClick={() => setDeleteModal(false)}
							></button>
						</header>
						<section className="modal-card-body">
              <p>Deleting your pet cannot be reversed and you will lose all the pet information. Are you sure you want to proceed?</p>
						</section>
						<footer className="modal-card-foot">
							<button
								className="button is-danger"
								onClick={() => deletePet()}
							>
								Yes, delete my pet
							</button>
							<button
								className="button is"
								onClick={() => setDeleteModal(false)}
							>
								Cancel
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default PetSingle;
