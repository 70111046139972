import React from "react";
import { AuthConsumer } from "../utils/AuthContext";
import Logo from "../general/logo";
import { Link } from "react-router-dom";

import "./nav.scss";

import LicensesIcon from "../../assets/icons8-renew.svg";
import PetFriendlyIcon from "../../assets/icons8-dog_park.svg";
import ScanIcon from "../../assets/icons8-portrait_mode_scanning.svg";
import PawIcon from "../../assets/icons8-cat_footprint.svg";

const ExternalNav = (props) => {
	return (
		<AuthConsumer>
			{({ isAuth, logout, domain }) => (
				<div>
					<aside className="menu">
						<div className="navbar-brand">
							<Link className="navbar-item" to="/user">
								<Logo logo={domain ? domain.logo : ""} />
							</Link>
						</div>

						<ul className="menu-list">
							<p className="menu-subtitle">
								<img src={PawIcon} height="40" alt="Licenses"></img>
								Profile
							</p>
							<li>
								<Link to="/user">Dashboard</Link>
							</li>
							<li>
								<Link to="/user/pets">My pets</Link>
							</li>
							<li>
								<Link to="/user/profile">My profile</Link>
							</li>
						</ul>

						<ul className="menu-list">
							<p className="menu-subtitle">
								<img src={LicensesIcon} height="40" alt="Licenses"></img>
								Licenses
							</p>
							<li>
								<Link to="/user/license">New license</Link>
							</li>
							{isAuth ? (
								<li>
									<Link to="/user/licenseRenew">Renew license</Link>
								</li>
							) : (
								""
							)}
						</ul>

						<ul className="menu-list">
							<p className="menu-subtitle">
								<img src={ScanIcon} height="40" alt="Scan a tag"></img>
								Tags
							</p>
							<li>
								<Link to="/user/scan">Scan tag</Link>
							</li>
						</ul>

						<ul className="menu-list">
							<p className="menu-subtitle">
								<img src={PetFriendlyIcon} height="40" alt="Scan a tag"></img>
								Pet friendly places
							</p>
							<li>
								<Link to="/user/restaurants">Restaurants</Link>
								<Link to="/user/hotels">Hotels</Link>
								<Link to="/user/services">Services</Link>
							</li>
						</ul>

						<ul className="menu-list">
							<li>
								{isAuth ? (
									<a onClick={(e) => logout(e)}>Logout</a>
								) : (
									<Link to="/user/login">Login</Link>
								)}
							</li>
						</ul>
					</aside>
				</div>
			)}
		</AuthConsumer>
	);
};

export default ExternalNav;
