import React, { useState } from "react";

const Step3 = (props) => {
	const [mailIn, setMailIn] = useState(false);

	const widget = window.cloudinary.createUploadWidget(
		{
			cloudName: "dnzyoe9rl",
			sources: ["local", "camera"],
			uploadPreset: "icmgqhdc",
		},
		(error, result) => {
			if (!error && result && result.event === "success") {
				console.log("Done! Here is the image info: ", result.info);
				props.setRabiesCert(result.info.secure_url);
			}
		}
	);

	const uploadFile = (e) => {
		e.preventDefault();
		widget.open();
	};

	return (
		<div
			className={
				props.step === 3
					? "columns is-centered tab-content active"
					: "columns is-centered tab-content"
			}
			id="tab1-content"
		>
			<div className="column is-10">
				<div className="columns is-multiline">
					<div className="column is-6">
						<div className="field">
							<label className="label">Rabies Certification Date</label>
							<div className="control">
								<input
									className="input"
									type="date"
									id="certDate"
									placeholder="26 July 2020"
									defaultValue="2020-07-30"
								/>
							</div>
						</div>
					</div>

					<div className="column is-6"></div>

					<div className="column is-6">
						<div className="field">
							<label className="label">Rabies Certification Duration</label>
							<div className="control">
								<div className="select is-fullwidth is-primary">
									<select
										id="rabiesTime"
										defaultValue="1"
										onChange={(e) => props.setRabiesDuration(e.target.value)}
									>
										<option value="1">1 year</option>
										<option value="2">2 years</option>
										<option value="3">3 years</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="column is-6"></div>

					<div className="column is-6 ">
						<div className="field">
							<label className="label">Rabies Certification</label>
							{/* <img id="file-preview"  style="max-height: 200px;"/> */}

							{!mailIn ? (
								<div className="control digital-certification">
									<div className="file is-boxed is-fullwidth has-text-centered">
										<label className="file-label">
											<input
												className="file-input"
												name="resume"
												id="file-upload"
												onClick={(e) => uploadFile(e)}
											/>
											<span
												className="file-cta"
												style={{
													backgroundImage: props.rabiesCert
														? "url(" + props.rabiesCert + ")"
														: "",
												}}
											>
												<span className="file-icon has-opacity-65">
													<svg
														className="is-16"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 24 24"
													>
														<path d="M20 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19h-.09a.88.88 0 0 0-.33-.11H7a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V9v-.06zm-6-3.53L16.59 8H15a1 1 0 0 1-1-1zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h5v3a3 3 0 0 0 3 3h3z" />
													</svg>
												</span>
												<span className="file-label has-opacity-65">
													{props.rabiesCert
														? "Replace file…"
														: "Choose a file..."}
												</span>
											</span>
										</label>
									</div>
								</div>
							) : (
								""
							)}
						</div>
						<label className="checkbox">
							<input
								type="checkbox"
								id="mail-cert"
								onChange={() => setMailIn(!mailIn)}
								defaultChecked={mailIn}
							/>
							I will mail in physical Certification
						</label>
						{mailIn ? (
							<div className="field" style={{ marginTop: "16px" }}>
								<p>
									You will have to send the Rabies Certification in{" "}
									<b>maximum 3 days</b> at this address:
								</p>

								<p
									className="has-text-weight-bold"
									style={{ marginTop: "16px" }}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										data-name="Layer 1"
										viewBox="0 0 24 24"
										style={{ marginLeft: "-20px", height: "16px" }}
									>
										<path d="M8 12h2a1 1 0 0 0 0-2H8a1 1 0 0 0 0 2zm9-6h-5V4h1a1 1 0 0 0 0-2h-2a1 1 0 0 0-1 1v3H7a4 4 0 0 0-4 4v6a1 1 0 0 0 1 1h6v4a1 1 0 0 0 2 0v-4h8a1 1 0 0 0 1-1v-6a4 4 0 0 0-4-4zm-4 4v5H5v-5a2 2 0 0 1 2-2h6.56a3.91 3.91 0 0 0-.56 2zm6 5h-4v-5a2 2 0 0 1 4 0z" />
									</svg>{" "}
									1029 N Stuart St., #300, Arlington, VA 22201
								</p>
							</div>
						) : (
							""
						)}
					</div>
					<div className="column is-6 is-flex is-flex-column is-justified-center digital-certification">
						<p
							className="is-size-7 has-opacity-65"
							style={{ marginBottom: "8px" }}
						>
							<svg
								className="is-16"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" />
							</svg>
							We have to verify that your pet’s rabies certificate is valid in
							order to register your pet.
						</p>
						{!mailIn ? (
							<p
								className="is-size-7 has-opacity-65"
								style={{ marginBottom: "8px" }}
							>
								<svg
									className="is-16"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" />
								</svg>
								Your file size must not exceed 5MB.
							</p>
						) : (
							""
						)}

						{!mailIn ? (
							<p
								className="is-size-7 has-opacity-65"
								style={{ marginBottom: "8px" }}
							>
								<svg
									className="is-16"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z" />
								</svg>
								Accepted formats: .pdf, .jpg
							</p>
						) : (
							""
						)}
					</div>

					<div className="column is-6">
						<div className="field">
							<label className="label">Tag duration</label>
							<div className="control">
								<div className="select is-fullwidth is-primary">
									<select
										id="certTime"
										defaultValue={props.tagDuration}
										onChange={(e) => props.setTagDuration(e.target.value)}
									>
										{props.rabiesDuration && props.rabiesDuration > 0 ? (
											<option value="1">1 year</option>
										) : (
											""
										)}
										{props.rabiesDuration && props.rabiesDuration > 1 ? (
											<option value="2">2 years</option>
										) : (
											""
										)}
										{props.rabiesDuration && props.rabiesDuration > 2 ? (
											<option value="3">3 years</option>
										) : (
											""
										)}
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="column is-6"></div>

					<div className="column is-8">
						<hr />
						{/* <p>Price is calculated depending on your dog's gender and it being spayed/neutered or unaltered.</p> */}
						<div className="card">
							<div className="card-content is-flex price">
								<p className="is-size-4">License price</p>
								<p className="is-size-4 has-text-weight-bold">
									${props.price ? props.price * props.tagDuration : "0"}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Step3;
