import React from "react";
// import FoodImg from '../../assets/food.jpg';
import NoProfile from "../../assets/noprofile.svg";
import "../profile/profileImage.scss";

const PetProfileImage = (props) => {
	const widget = window.cloudinary.createUploadWidget(
		{
			cloudName: "dnzyoe9rl",
			sources: ["local", "camera"],
			uploadPreset: "icmgqhdc",
		},
		(error, result) => {
			if (!error && result && result.event === "success") {
				console.log("Done! Here is the image info: ", result.info);
				props.setPetProfileImage(result.info.secure_url);
			}
		}
	);

	const uploadFile = (e) => {
		e.preventDefault();
		widget.open();
	};

	return (
		<div>
			<label className="label">Profile image</label>
			<div className="card profile-image">
				<img
					src={
						props.petProfileImage
							? props.petProfileImage.includes("cloudinary")
								? props.petProfileImage
								: "https://api.roamingtails.co/uploads/profiles/" +
								  props.petProfileImage
							: NoProfile
					}
					alt="User profile"
					className="is-block"
				/>
				{!props.noChange ? (
					<div
						className="profile-image__upload has-background-dark"
						onClick={(e) => uploadFile(e)}
					>
						<a className="has-text-white">
							{props.profileImage ? "Add image" : "Change image"}
						</a>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default PetProfileImage;
