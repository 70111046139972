import React from 'react';
import {Elements, CardElement} from 'react-stripe-elements';
import {StripeProvider} from 'react-stripe-elements';


const Step5 = (props) => {

  return (
    <div className={props.step===5?"columns is-centered tab-content active":"columns is-centered tab-content"} id="tab1-content">
      <div className="column is-10">
        <div className="columns is-multiline">
          <div className="column is-6">
  				  <div className="form-row">
  						<label className="label">Payment details</label>  
              <StripeProvider apiKey="pk_test_12345">
                <Elements>
                  <CardElement style={{base: {fontSize: '18px'}}} />
                </Elements>
              </StripeProvider>
  				    <div id="card-element">
  				      {/*  A Stripe Element will be inserted here.  */}
  				    </div>

  				    {/* Used to display form errors. */}
  				    <div id="card-errors" role="alert"></div>
  				  </div>
  				</div>
          <div className="column is-6"></div>
          <div className="column is-6">
						<label className="checkbox" style={{margin: "24px 0"}}>
						  <input type="checkbox" id="auto-renew" defaultChecked />
						  Auto-renew enabled
						</label>
						<p>You will be reminded 6 months before the auto-renewal and you will always be able to opt-out.</p>
					</div>
        </div>
      </div>
    </div>
  );
}

export default Step5;
