import React, { useState, useEffect } from "react";
import WelcomeIllustration from '../../assets/welcome-illustration.svg'
import axios from 'axios'
import { Line } from 'react-chartjs-2';
import Skeleton from "react-loading-skeleton";
// import Faqs from './faqs';
// import Tag from '../general/tag';

const DashboardAdmin = (props) => {
  const [pets, setPets] = useState();

  useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "pets", {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setPets(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'New Licenses',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(131,175,242,0.4)',
        borderColor: 'rgba(131,175,242,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(131,175,242,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(131,175,242,1)',
        pointHoverBorderColor: '#123873',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [2043, 1924, 2400, 1432, 2604, 2803]
      }
    ],
  };

  const data2 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Compliance rate %',
        fill: true,
        lineTension: 0.1,
        backgroundColor: 'rgba(131,175,242,0.4)',
        borderColor: 'rgba(131,175,242,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(131,175,242,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(131,175,242,1)',
        pointHoverBorderColor: '#123873',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [2, 7, 12, 14, 17, 21, 26]
      }
    ],
  };

  const options = {
    legend: {
        display: false,
    },
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true
          }
      }]
    }
  };
  

  return (
    <div>
      <section className="section">
        <div className="columns is-multiline is-vcentered">
          <div className="column is-3">
            <img src={WelcomeIllustration} alt="Person greeting dog" />
          </div>
          <div className="column is-7">
            <p className="is-size-4 has-text-weight-bold">Welcome back!</p>
            <p>Have a look at how your county is doing with pet licensing</p>
          </div>
        </div>
      </section>

      {/* <section className="section">
        <div className="columns is-multiline">
          <div className="column is-12">
            <p className="has-text-weight-bold">Pending licenses</p>
          </div>

          <div className="column is-7">
            <p>You have no pending tasks!</p>
            <Tag type="warning">
              <a>New license request by Mike O’Brian for his pet, Jumbo.</a>
            </Tag>
          </div>
        </div>
      </section> */}

      <section className="section">
        <div className="columns">
          <div className="column is-6">
            <div className="columns is-multiline">

              <div className="column is-6">
                <div className="card">
                  <div className="card-content">
                    <p className="is-size-7">Revenue this month</p>
                    <p className="is-size-4 has-text-weight-bold">$120,000</p>
                    <p className="is-size-7">Compared to last month</p>
                    <p className="is-size-4 has-text-weight-bold">
                      7%
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="50" height="50" className="is-24 top-2px"
                        viewBox="0 0 172 172"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#8FC238"><path d="M86,26.3375l-2.4725,2.365l-37.41,37.5175c-1.02125,0.83313 -1.49156,2.16344 -1.19594,3.45344c0.29562,1.27656 1.30344,2.28438 2.58,2.58c1.29,0.29563 2.62031,-0.17469 3.45344,-1.19594l31.605,-31.605v101.5875c-0.01344,1.23625 0.63156,2.39188 1.70656,3.02344c1.075,0.61813 2.39187,0.61813 3.46687,0c1.075,-0.63156 1.72,-1.78719 1.70656,-3.02344v-101.5875l31.605,31.605c0.83313,1.02125 2.16344,1.49156 3.45344,1.19594c1.27656,-0.29562 2.28438,-1.30344 2.58,-2.58c0.29563,-1.29 -0.17469,-2.62031 -1.19594,-3.45344l-37.41,-37.5175z"></path></g></g></svg>
                    </p>  
                  </div>
                </div>
              </div>

              <div className="column is-6">
                <div className="card">
                  <div className="card-content">
                    <p className="is-size-7">Total pets</p>
                    <p className="is-size-4 has-text-weight-bold">{pets ? pets.length : <Skeleton height={24} width={160} />}</p>
                    <p className="is-size-7">Total active licenses</p>
                    <p className="is-size-4 has-text-weight-bold">{pets ? pets.length : <Skeleton height={24} width={160} />}</p>
                  </div>
                </div>
              </div>

              <div className="column is-12">
                <div className="card">
                  <div className="card-content content">
                    <p className="has-text-weight-bold">
                      New Licenses
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="50" height="50" className="is-24 top-6px"
                        viewBox="0 0 172 172"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#8FC238"><path d="M86,26.3375l-2.4725,2.365l-37.41,37.5175c-1.02125,0.83313 -1.49156,2.16344 -1.19594,3.45344c0.29562,1.27656 1.30344,2.28438 2.58,2.58c1.29,0.29563 2.62031,-0.17469 3.45344,-1.19594l31.605,-31.605v101.5875c-0.01344,1.23625 0.63156,2.39188 1.70656,3.02344c1.075,0.61813 2.39187,0.61813 3.46687,0c1.075,-0.63156 1.72,-1.78719 1.70656,-3.02344v-101.5875l31.605,31.605c0.83313,1.02125 2.16344,1.49156 3.45344,1.19594c1.27656,-0.29562 2.28438,-1.30344 2.58,-2.58c0.29563,-1.29 -0.17469,-2.62031 -1.19594,-3.45344l-37.41,-37.5175z"></path></g></g></svg>
                      </p>
                    <Line 
                      data={data} 
                      options={options} />
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="column is-6">
            <div className="card">
              <div className="card-content content">
                <p className="has-text-weight-bold">Compliance rate (%)</p>
                <Line 
                  data={data2} 
                  options={options} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardAdmin;
