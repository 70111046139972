import React from 'react';
import TitleBanner from '../general/titleBanner';

const Stats = (props) => {
  return (
    <div>
      <section class="section">
        <TitleBanner title="Stats"/>
      </section>
    </div>
  )
}

export default Stats;
