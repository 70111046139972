import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Map from "../map/map";
import Skeleton from "react-loading-skeleton";

const ScanResultMap = (props) => {
	const [location, setLocation] = useState();

	useEffect(() => {
		console.log(props.match.params.id);
		axios
			.get(
				process.env.REACT_APP_API_URL +
					"tags/locations/getLocationsFromEmail/" +
					props.match.params.id,
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				console.log(res.data.locations);
				setLocation(res.data.locations);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div>
			{location ? <Map type="petScanned" data={location}></Map> : <Skeleton height={240} width={"100%"} />}
		</div>
	);
};

export default ScanResultMap;
