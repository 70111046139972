import React from 'react';
import '../../stylesheets/titleBanner.scss';

const TitleBanner = (props) => {
  // console.log(props);
  return (
    <div className="card has-text-centered title-banner">
      <div className="card-content">
        <img src={props.leftImg} className="is-icon" style={{top: props.leftTop}} alt={props.leftImg}></img>
        <p className="has-text-weight-bold is-size-4">{props.title}</p>
        <p>{props.subtitle}</p>
        <img src={props.rightImg} className="is-icon" style={{top: props.rightTop}} alt={props.rightImg}></img>
      </div>
    </div>
  )
}

export default TitleBanner;
