import React, { useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';

import Table from '../general/table';
import ActionBar from '../general/actionBar';
// import Input from '../general/input';
import Dropdown from '../general/dropdown';
import TitleBanner from '../general/titleBanner';

import axios from 'axios';
import moment from 'moment';

import { CSVLink } from "react-csv";

import LicenseIllustration from '../../assets/license-illustration.svg';
import SearchIcon from '../../assets/icons8-search.svg';

const Licenses = (props) => {

  const [licenses, setLicenses] = useState();

  useEffect(() => {
    if (props.county!== null && props.county!== undefined) {
        axios.get('http://localhost:1337/licenses', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}  `,
          },
        })
        .then(function (response) {
          console.log(response);
          setLicenses(response.data)
        })
        .catch(function (error) {
          console.log(error);
        });
      }
  }, []);

  const selectRow = (e) => {
    console.log(e.target);
    e.target.closest('tr').classList.toggle('is-selected');
  }

  let csvRows = []
  if (licenses) {
    licenses.forEach((item, index) => {
        csvRows.push(item);
      })
  }
  // console.log(csvRows);

  let csvData;
  if (licenses) {
    csvData = csvRows;
    // console.log(csvData)
  }

  return (
    <div>
      <ActionBar
        leftSide={
        <div className="field has-addons">
          <div className="control">
            <button className="button is-static">
              <img src={SearchIcon} height="16" className="is-icon is-16" alt="Search..."></img>
            </button>
          </div>
          <div className="control is-expanded">
            <input className="input" type="text" placeholder="License number or pet name" />
          </div>
        </div> }
        // rightSide={
        //   <button class="button is-small is-white">
        //     <span>Filter</span>
        //     <span class="icon is-small">
        //       <svg className="is-16" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50"><path d="M 3.8125 2 C 3.335938 2.089844 2.992188 2.511719 3 3 L 3 6 C 3.003906 6.257813 3.101563 6.503906 3.28125 6.6875 L 19 23.375 L 19 41 C 19.007813 41.347656 19.199219 41.667969 19.5 41.84375 L 29.5 47.84375 C 29.804688 48.019531 30.183594 48.023438 30.492188 47.847656 C 30.796875 47.675781 30.992188 47.351563 31 47 L 31 23.375 L 46.5625 6.84375 C 46.574219 6.832031 46.582031 6.824219 46.59375 6.8125 L 46.71875 6.6875 C 46.765625 6.640625 46.808594 6.585938 46.84375 6.53125 C 46.867188 6.511719 46.886719 6.492188 46.90625 6.46875 C 46.964844 6.339844 46.996094 6.203125 47 6.0625 C 47 6.042969 47 6.019531 47 6 C 47.003906 5.949219 47.003906 5.894531 47 5.84375 L 47 3 C 47 2.449219 46.550781 2 46 2 L 4 2 C 3.96875 2 3.9375 2 3.90625 2 C 3.875 2 3.84375 2 3.8125 2 Z M 5 4 L 45 4 L 45 5.625 L 29.5625 22 L 20.4375 22 L 5 5.625 Z M 21 24 L 29 24 L 29 45.25 L 21 40.46875 Z"></path></svg>
        //     </span>
        //   </button>
        // }
      />

        <section className="section">
          <TitleBanner
            leftImg={LicenseIllustration}
            rightImg={LicenseIllustration}
            title="Licenses"
            subtitle={(licenses?licenses.length:"0") + " licenses found"}
          />
          {licenses?
            <div className="field">
              {/* <p>Export table as a .csv file</p> */}
              <CSVLink className="button is-primary" data={csvData}>Export .csv</CSVLink>
            </div>:
          ""}
        </section>

        <section className="section">
          <div className="level">
            <div className="level-left">
              <Dropdown name="Actions">
                <button className="dropdown-item">
                  Delete
                </button>
                <button className="dropdown-item">
                  Send
                </button>
              </Dropdown>
            </div>
            {/* <div className="level-right">
              <button className="button is-primary">+ Add pet</button>
            </div> */}
          </div>

          <div className="columns">
            <div className="column is-12">
              <Table thTitles={["Name" , "License #", "License year", "Age", "Microchip #", "Gender", "Breed"]}>
                {licenses?licenses.map((license, index) => {
                  return (
                    <tr key={index}>
                        <td nowrap="true" >
                          <input type="checkbox" onChange={(e) => selectRow(e)}></input>
                        </td>
                        <td nowrap="true">{license.index}</td>
                        <td nowrap="true">{license.fname}</td>
                        <td nowrap="true">{license.lname}</td>
                        <td nowrap="true">{license.email}</td>
                        <td nowrap="true">{license.street}</td>
                        <td nowrap="true">{license.city}</td>
                        <td nowrap="true">{license.state}</td>
                        <td nowrap="true">{license.county}</td>
                        <td nowrap="true">{license.zip}</td>
                        <td nowrap="true">{license.petType}</td>
                        <td nowrap="true">{license.petName}</td>
                        <td nowrap="true">{moment(license.petDob).format('ll')}</td>
                        <td nowrap="true">{license.petWeight}</td>
                        <td nowrap="true">{license.petBreed}</td>
                        <td nowrap="true">{license.petColor}</td>
                        <td nowrap="true">{license.petHair}</td>
                        <td nowrap="true">{license.petGender}</td>
                        <td nowrap="true">{license.status}</td>
                        <td nowrap="true">{moment(license.certDate).format('ll')}</td>
                        <td nowrap="true"><a href={license.cert} target="_blank" rel="noopener noreferrer">Certificate file</a></td>
                        <td nowrap="true">{license.certDuration}</td>
                        <td nowrap="true">{license.autoRenew}</td>
                        <td nowrap="true">{license.certVerified}</td>
                        <td nowrap="true">{license.tagNo}</td>
                    </tr>
                  )
                }):  <tr><td></td><td nowrap="true">No licenses</td></tr>}
              </Table>
            </div>
          </div>
        </section>

    </div>
  );
}

export default Licenses;
