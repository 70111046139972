import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import PetProfileImage from "../pets/petProfileImage";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import Tag from "../general/tag";

const ScanResultQR = (props) => {
	const [pet, setPet] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		getLocation();
	}, []);

	const getPet = () => {
		axios
			.get(process.env.REACT_APP_API_URL + "pet/tag/" + props.match.params.id, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data);
				if (!res.data.success) {
					toast.error(res.data.message);
				} else {
					setPet(res.data.data);
					// props.history.push('/user/scan/'+res.data.data.id)
				}
			})
			.catch((err) => {
				console.log(err);
				// toast.error(err.data.message);
			});
	};

	const scanTag = (lat, lng) => {
		axios
			.post(
				process.env.REACT_APP_API_URL + "tag/scanner",
				{
					tag_number: props.match.params.id,
					latitude: lat,
					longitude: lng,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				if (!res.data.success) {
					toast.error(res.data.message);
				} else {
					getPet(res.data.data.tag_number);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getLocation = () => {
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0,
		};
		function success(pos) {
			var crd = pos.coords;
			console.log("Your current position is:");
			console.log(`Latitude : ${crd.latitude}`);
			console.log(`Longitude: ${crd.longitude}`);
			console.log(`More or less ${crd.accuracy} meters.`);
			scanTag(crd.latitude, crd.longitude);
			setError("");
		}
		function error(err) {
			setError(
				"You don't have location services activated, so the owner doesn't get alerted with the location of the scan. Please contact the owner of the pet yourself!"
			);
			getPet();
			console.warn(`ERROR(${err.code}): ${err.message}`);
		}
		navigator.geolocation.getCurrentPosition(success, error, options);
	};

	return (
		<div>
			<section className="section">
				<div className="columns">
					{error ? (
						<div className="column is-12">
							<Tag type="danger">{error}</Tag>
						</div>
					) : (
						""
					)}
					<div className="column is-12">
						<p className="has-text-weight-bold is-size-4">
							{pet ? pet.name : ""}'s information
						</p>
					</div>
				</div>

				<div className="columns">
					<div className="column is-3">
						{pet ? (
							<PetProfileImage petProfileImage={pet.image} noChange={true} />
						) : (
							<Skeleton height={120} width={"100%"} />
						)}
					</div>
					<div className="column is-9">
						<div className="columns is-multiline">
							<div className="column is-6">
								<p className="is-size-7">Address 1</p>
								<p className="has-text-weight-bold">
									{pet && pet.address1 ? pet.address1 : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Address 2</p>
								<p className="has-text-weight-bold">
									{pet && pet.address2 ? pet.address2 : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Apt</p>
								<p className="has-text-weight-bold">
									{pet && pet.apt ? pet.apt : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Owner email</p>
								<p className="has-text-weight-bold">
									{pet && pet.email ? pet.email : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Owner phone</p>
								<p className="has-text-weight-bold">
									{pet && pet.phone ? pet.phone : "N/A"}
								</p>
							</div>

							<div className="column is-6"></div>

							<div className="column is-6">
								<p className="is-size-7">Name</p>
								<p className="has-text-weight-bold">{pet ? pet.name : "N/A"}</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Species</p>
								<p className="has-text-weight-bold">
									{pet && pet.type ? pet.type : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Breed</p>
								<p className="has-text-weight-bold">
									{pet && pet.breed ? pet.breed : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Hair</p>
								<p className="has-text-weight-bold">
									{pet && pet.hair ? pet.hair : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Hair color</p>
								<p className="has-text-weight-bold">
									{pet && pet.color ? pet.color : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Date of birth</p>
								<p className="has-text-weight-bold">
									{pet && pet.dob ? moment(pet.dob).format("MMM Do YY") : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Gender</p>
								<p className="has-text-weight-bold">
									{pet && pet.gender ? pet.gender : "N/A"}
								</p>
							</div>
							<div className="column is-6">
								<p className="is-size-7">Spayed/Neutered</p>
								<p className="has-text-weight-bold">
									{pet && pet.spayed ? pet.spayed : "N/A"}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ScanResultQR;
