import React from 'react';

const FaqSingle = (props) => {
  return (
    <div className="card">
      <header className="card-header is-shadowless has-background-primary">
        <p className="card-header-title">
          <svg className="is-16 is-white" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
              <path fill="none" stroke="#fff" strokeWidth="4" strokeMiterlimit="10" d="M32 50V14M14 32h36"/>
          </svg>
          {props.question}
         </p>
      </header>
      <div className="card-content ">
        {props.answer}
      </div>
    </div>
  )
}

export default FaqSingle;
