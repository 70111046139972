import React, { useState, useEffect } from "react";
import PetPreview from "./petPreview";
import axios from "axios";
import Skeleton from "react-loading-skeleton";

const PetsListPreview = (props) => {
	const [pets, setPets] = useState();

	useEffect(() => {
		axios
			.get(process.env.REACT_APP_API_URL + "pets", {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				setPets(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div>
			<div className="columns">
				<div className="column is-12">
					<p className="is-size-4 has-text-weight-bold">My pets</p>
				</div>
			</div>

			<div className="columns is-multiline">
				{/* loop through user's pets, for each pet display a preview */}
				{pets ? (
					pets.map((pet) => {
						return (
							<div className="column is-7" key={pet.id}>
								<PetPreview pet={pet} />
							</div>
						);
					})
				) : (
					<div className="column is-7">
						<div className="card is-horizontal is-flex">
							<div className="card-image is-flex is-vcentered">
								<Skeleton  height="100%" width={200} />
							</div>
							<div className="card-content">
                <div>
                <Skeleton height={48} width={160} />
                </div>
                <Skeleton count={3} height={24} width={240} />
              </div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default PetsListPreview;
