import React, { useState } from "react";
import { AuthConsumer } from "../utils/AuthContext";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Tag from "../general/tag";

const ResetPassword = (props) => {
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const resetPassword = (e) => {
		e.preventDefault();
		setError("");
		setLoading(true);
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"password/reset?email=" +
					document.getElementById("reset-email").value,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}  `,
					},
				}
			)
			.then(function (response) {
				console.log(response);
				setSuccess(response.data.message);
				setLoading(false);
			})
			.catch(function (error) {
				setError("Please make sure you input a valid email");
				setLoading(false);
			});
	};

	return (
		<div className="container">
			<section className="section pb-2">
				<button onClick={() => history.goBack()} className="button">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						className="is-24 mr-2 "
						fill="#000000"
					>
						<path
							d="M 10 4.9296875 L 2.9296875 12 L 10 19.070312 L 11.5 17.570312 L 6.9296875 13 L 21 13 L 21 11 L 6.9296875 11 L 11.5 6.4296875 L 10 4.9296875 z"
							fill="#000000"
						/>
					</svg>
					Back to login
				</button>
			</section>
			<section className="section">
				<div className="field">
					<label className="label">Email</label>
					<div className="control">
						<input
							className="input"
							type="email"
							placeholder="Email"
							id="reset-email"
						/>
					</div>
				</div>

				<div className="field">
					{error ? <Tag type="danger">{error}</Tag> : ""}
					{success ? <Tag type="success">{success}</Tag> : ""}
				</div>

				<div className="field">
					<div className="control">
						<button
							className={
								loading ? "button is-primary is-loading" : "button is-primary"
							}
							onClick={(e) => resetPassword(e)}
						>
							Reset password
						</button>
					</div>
				</div>
			</section>
		</div>
	);
};

export default ResetPassword;
