import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Tag from "../general/tag";
import axios from "axios";

const NewPassword = (props) => {
	const history = useHistory();
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState();

	const submitNewPassword = () => {
		setError("");
		setLoading(true);
		axios
			.post(
				process.env.REACT_APP_API_URL +
					"password/change/" +
					props.match.params.id +
					"/" +
					document.getElementById("new-password").value,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}  `,
					},
				}
			)
			.then(function (response) {
				if (!response.data.success) {
					setError(response.data.message);
				} else {
					setSuccess(response.data.message);
				}
				setLoading(false);
				console.log(response);
			})
			.catch(function (error) {
				setError("Something went wrong, please try again");
				setLoading(false);
				console.log(error);
			});
	};

	const validatePassword = () => {
		let isValid = true;
		const newPassword = document.getElementById("new-password");
		const repeatPassword = document.getElementById("repeat-new-password");

		if (!newPassword.value) {
			isValid = false;
			setError("Please enter your new password.");
			return;
		}

		if (!repeatPassword.value) {
			isValid = false;
			setError("Please enter your confirm password.");
			return;
		}

		if (
			typeof newPassword.value !== "undefined" &&
			typeof repeatPassword.value !== "undefined"
		) {
			if (newPassword.value != repeatPassword.value) {
				isValid = false;
				setError("Passwords don't match.");
				return;
			}
		}

		submitNewPassword();
	};

	return (
		<div className="container">
			<section className="section">
				<div className="field">
					<label className="label">New password</label>
					<div className="control">
						<input
							className="input"
							type="input"
							placeholder=""
							id="new-password"
						/>
					</div>
				</div>

				<div className="field">
					<label className="label">Repeat new password</label>
					<div className="control">
						<input
							className="input"
							type="input"
							placeholder=""
							id="repeat-new-password"
						/>
					</div>
				</div>

				{error ? <Tag type="danger">{error}</Tag> : ""}
				{success ? <Tag type="success">{success}</Tag> : ""}

				<div className="field">
					<div className="control">
						<button
							className={
								loading ? "button is-primary is-loading" : "button is-primary"
							}
							onClick={() => validatePassword()}
						>
							Set new password
						</button>
					</div>
				</div>
			</section>
		</div>
	);
};

export default NewPassword;
