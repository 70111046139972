import React, { useState } from "react";
import axios from "axios";
import Input from "../general/input";
import FaqSingle from "../general/faq-single";
import { toast } from "react-toastify";

import LostBanner from "../../assets/lost-banner.png";
import ScanResult from "./scanResult";

const Scan = (props) => {
	const [pet, setPet] = useState();
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [permission, askPermission] = useState(false);
	const [error, setError] = useState();

	const scanTagNoLocation = () => {
		axios
			.get(
				process.env.REACT_APP_API_URL +
					"pet/tag/" +
					document.querySelector("#sTag-number").value,
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				setPet(res.data.data);
				setModal(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const scanTag = (lat, lng) => {
		setLoading(true);
		axios
			.post(
				process.env.REACT_APP_API_URL + "tag/scanner",
				{
					tag_number: document.querySelector("#sTag-number").value,
					latitude: lat,
					longitude: lng,
				},
				{
					headers: {
						Accept: "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				setLoading(false);
				if (!res.data.success) {
					toast.error(res.data.message);
				} else {
					setModal(true);
					// console.log(res.data)
					// console.log(res.data.data.tag_number)
					getPet(res.data.data.tag_number);
					// setPet(res.data.data);
					// props.history.push('/user/scan/'+res.data.data.id)
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				// toast.error(err.data.message);
			});
	};

	const getPet = (tagNo) => {
		axios
			.get(process.env.REACT_APP_API_URL + "pet/tag/" + tagNo, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
				console.log(res.data);
				setLoading(false);
				if (!res.data.success) {
					toast.error(res.data.message);
				} else {
					setModal(true);
					setPet(res.data.data);
					// props.history.push('/user/scan/'+res.data.data.id)
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				// toast.error(err.data.message);
			});
	};

	const getLocation = () => {
		setLoading(true);
		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0,
		};
		function success(pos) {
			var crd = pos.coords;
			console.log("Your current position is:");
			console.log(`Latitude : ${crd.latitude}`);
			console.log(`Longitude: ${crd.longitude}`);
			console.log(`More or less ${crd.accuracy} meters.`);
			scanTag(crd.latitude, crd.longitude);
			setLoading(false);
			askPermission(false);
			setError("");
		}
		function error(err) {
			setLoading(false);
      askPermission(false);
			setError(
				"You don't have location services activated, so the owner doesn't get alerted with the location of the scan. Please contact the owner of the pet yourself!"
			);
			scanTagNoLocation();
			console.warn(`ERROR(${err.code}): ${err.message}`);
		}
    navigator.geolocation.getCurrentPosition(success, error, options);
	};

	const checkPermission = () => {
    if (navigator.permissions) {
      setLoading(false);
			navigator.permissions.query({ name: "geolocation" }).then((result) => {
				console.log(result);
				if (result.state == "prompt") {
					setError("");
					askPermission(true);
				} else if (result.state == "granted") {
					setError("");
					getLocation();
				} else if (result.state == "denied") {
					setError(
						"You don't have location services activated, so the owner doesn't get alerted with the location of the scan. Please contact the owner of the pet yourself!"
					);
					scanTagNoLocation();
				}
			});
    } else {
      getLocation()
    }
  };

	return (
		<div>
			<div
				className="banner"
				style={{ backgroundImage: "url(" + LostBanner + ")" }}
			>
				<section className="hero">
					<div className="hero-body">
						<div className="container">
							<p className="is-size-4 has-text-weight-bold has-text-white">
								Found a pet? Scan the tag here!
							</p>
							<p className="has-text-white">
								Easily alert the owner of the pet's location
							</p>
						</div>
					</div>
				</section>
			</div>

			<section className="section">
				<div className="columns">
					<div className="column is-4 content">
						<p className="is-size-3 is-marginless">1</p>
						<p className="has-text-weight-bold">
							Scan the QR Code on the tag or input the tag number below
						</p>
						<p>
							Input the 10-digit number that is on the back of the RoamingTail
							and hit “Find”!
						</p>
						<Input
							hasAddon={true}
							addonContent={
								<button
									onClick={() => checkPermission()}
									className={
										loading
											? "button is-primary is-loading"
											: "button is-primary"
									}
								>
									Find
								</button>
							}
							placeholder="10-digit tag number"
							id="sTag-number"
						/>
					</div>

					<div className="column is-4 content">
						<p className="is-size-3 is-marginless">2</p>
						<p className="has-text-weight-bold">View Pet Owner's information</p>
						<p>
							A page should open up where you will be able to see all the public
							information that pet and pet owner have, including contact details
						</p>
					</div>

					<div className="column is-4 content">
						<p className="is-size-3 is-marginless">3</p>
						<p className="has-text-weight-bold">Contact the pet owner</p>
						<p>
							Contact the pet owner by using the information provided in the
							pet's profile. Give yourself a pat on the back for helping a furry
							friend find their way home.
						</p>
					</div>
				</div>
			</section>

			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<div className="card">
							<div className="card-content">
								<p className="has-text-weight-bold is-size-4">
									Tips to take care of a lost pet
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="columns is-multiline">
					<div className="column is-6">
						<FaqSingle
							question="Ask owner via phone if the pet has any special needs"
							answer="Ask about any special medical needs the pet might have and determine if the pet is allergic to anything."
						/>
					</div>
					<div className="column is-6">
						<FaqSingle
							question="Give the pet water"
							answer="Make sure the pet stays hydrated"
						/>
					</div>
					<div className="column is-6">
						<FaqSingle
							question="Comfort the pet"
							answer="YThis pet has been through a lot and is likely homesick. Make this pet feel at home"
						/>
					</div>
				</div>
			</section>

			{pet && modal ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => setModal(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Pet information</p>
							<button
								className="delete"
								onClick={() => setModal(false)}
							></button>
						</header>
						<section className="modal-card-body">
							{error ? (
								<p className="has-text-white has-background-danger py-3 px-3">
									{error}
								</p>
							) : (
								""
							)}
							<ScanResult pet={pet ? pet : ""} />
						</section>
						<footer className="modal-card-foot">
							{/* <button
								className="button is-primary"
							>
								Call owner
							</button> */}
							<button className="button is" onClick={() => setModal(false)}>
								Close
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}

			{permission ? (
				<div className="modal is-active">
					<div
						className="modal-background"
						onClick={() => askPermission(false)}
					></div>
					<div className="modal-card">
						<header className="modal-card-head">
							<p className="modal-card-title">Ask permission</p>
							<button
								className="delete"
								onClick={() => askPermission(false)}
							></button>
						</header>
						<section className="modal-card-body">
							<div className="content">
								<p>
									Do you want to share the location of where the pet tag was
									scanned from? This can potentially help very much the pet
									owner.
								</p>
								<p>
									If you chose to do so, press this button{" "}
									<button
										className={
											loading
												? "button is-primary is-loading"
												: "button is-primary"
										}
										onClick={() => getLocation()}
										style={{ marginTop: "-6px" }}
									>
										Ask permission nicely
									</button>{" "}
									and you will receive a prompt from your browser, similar to
									this one:
								</p>
								<img
									src="https://www.cnet.com/a/img/MV0AGgNOFAZAhvYyWd4rNmodYrE=/1200x675/2017/02/28/095c9593-2d51-47db-9da6-c648d3735464/mapquest-know-your-location.jpg"
									alt=""
									style={{ height: "200px" }}
								/>
								<p>
									Press "Allow" and you'll be ready - the found pet and its
									owner will be thankful for it!
								</p>
							</div>
						</section>
						<footer className="modal-card-foot">
							<button
								className="button is"
								onClick={() => askPermission(false)}
							>
								Close
							</button>
						</footer>
					</div>
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Scan;
