import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import Input from "../general/input";
import ActionBar from "../general/actionBar";
import { useHistory } from "react-router-dom";

const PetNew = (props) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

	const addPet = () => {
    setLoading(true);
		let petName = document.getElementById("petName");
		let petSpecies = document.getElementById("petSpecies");
		let petBreed = document.getElementById("petBreed");
		let petHair = document.getElementById("petHair");
		let petColor = document.getElementById("petColor");
		let petDob = document.getElementById("petDob");
		let petGender = document.getElementById("petGender");
		let petSpayed = document.getElementById("petSpayed");

		let data = {
			owner_id: props.user.id,
			// id: data.pet_id,
			name: petName.value,
			type: "Dog",
			// other_type: data.other_type,
			dob: petDob.value,
			breed: petBreed.value,
			color: petColor.value,
			gender: petGender.value,
			// vet_name: data.vet_name,
			// vet_number: data.vet_number,
			// image: values.image,
			// address1: data.address1,
			// address2: data.address2,
			// apt: data.apt,
			// city: data.city,
			// zipcode: data.zipcode,
			// state: data.state,
			// country_code: data.country_code,
			// tag_number: data.tag_number,
			// description: data.description,
			// vet_address: data.vet_address,
			// medical_records: values.medical_records,
			// crc: values.crc,
			// insurance_name: data.insurance_name,
			// insurance_id: data.insurance_id,
			// microchip_id: data.microchip_id,
			// opt_out_dashboard_img: data.opt_out_dashboard_img,
			// dangerous_pet: 0,
			// spayed_neutered: true / false,
		};
		axios
			.post(process.env.REACT_APP_API_URL + "pets", data, {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			})
			.then((res) => {
        setLoading(false);
        if (res.success == false) {
          console.log(res.data);
          toast.error("Please fill in all information!");
        } else {
          console.log(res.data);
          toast.success("Pet added!");
          history.push("/user/pets")
        }
			})
			.catch((err) => {
				console.log(err);
        setLoading(false);
				toast.error(err.data.message);
			});
	};

	return (
		<div>
			<ActionBar
				{...props}
				back={true}
				rightSide={
					<div className="buttons">
						<button
							className="button is-text is-small"
							onClick={() => props.history.goBack()}
						>
							Cancel
						</button>
						<button
							className={loading ? "button is-success is-loading is-small" : "button is-success is-small"}
							onClick={() => addPet()}
						>
							Add pet
						</button>
					</div>
				}
			/>

			<section className="section">
				<div className="columns">
					<div className="column is-12">
						<p className="has-text-weight-bold is-size-4">Add a new pet</p>
					</div>
				</div>

				<div className="columns">
					<div className="column is-9">
						<div className="columns is-multiline">
							<div className="column is-4">
								<Input label="Name" placeholder="Shorty" id="petName" />
							</div>
							<div className="column is-4">
								<Input label="Species" placeholder="Dog" id="petSpecies" />
							</div>
							<div className="column is-4">
								<Input label="Breed" placeholder="Beagle" id="petBreed" />
							</div>
							<div className="column is-4">
								<Input label="Hair" placeholder="Short" id="petHair" />
							</div>
							<div className="column is-4">
								<Input
									label="Hair color"
									placeholder="Brown and white"
									id="petColor"
								/>
							</div>
							<div className="column is-4">
								<div className="field">
									<label className="label">Pet date of birth</label>
									<div className="control">
										<input
											className="input"
											type="date"
											id="petDob"
											placeholder="26 July 2019"
										/>
									</div>
								</div>
							</div>
							<div className="column is-4">
								<Input label="Gender" placeholder="Male" id="petGender" />
							</div>
							<div className="column is-4">
								{/* Replace with select dropdown -> true/false */}
								<div>
									<label className="label">Spayed/Neutered</label>
									<div className="select is-fullwidth">
										<select id="petSpayed">
											<option value="1">Yes</option>
											<option value="0">Unaltered</option>
										</select>
									</div>
								</div>
								{/* <Input
									label="Spayed/Neutered"
									placeholder="Yes"
									value={pet ? pet.spayed_neutered : ""}
									id="petSpayed"
								/> */}
							</div>

							<div className="column is-12">
								<button
									className={loading ? "button is-success is-loading" : "button is-success"}
									onClick={() => addPet()}
								>
									Add pet
								</button>
							</div>
						</div>
					</div>
					{/* <div className="column is-9">
						<div className="columns is-multiline">
							<div className="column is-4">
								<Input label="Name" placeholder="Radu" />
							</div>
							<div className="column is-4">
								<Input label="Species" placeholder="Dog" />
							</div>
							<div className="column is-4">
								<Input label="Breed" placeholder="Beagle" />
							</div>
							<div className="column is-4">
								<Input label="Hair" placeholder="Short" />
							</div>
							<div className="column is-4">
								<Input label="Hair color" placeholder="Brown and white" />
							</div>
							<div className="column is-4">
								<Input label="Date of birth" placeholder="" />
							</div>
							<div className="column is-4">
								<Input label="Gender" placeholder="Male" />
							</div>
							<div className="column is-4">
								<Input label="Spayed/Neutered" placeholder="Yes" />
							</div>
						</div>
					</div> */}
				</div>
			</section>
		</div>
	);
};

export default PetNew;
