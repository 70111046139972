import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (document.querySelector('.is-content')) {
      document.querySelector('.is-content').scrollTo(0, 0);  
    }
  }, [pathname]);

  return null;
}
