import React from 'react';
import '../../stylesheets/tag.scss';

const Tag = (props) => {
  // Accepts Bulma types: primary, link , info, success, danger, warning

  let icon;
  switch (props.type) {
    case "warning":
    icon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className="is-16 is-white" width="100" height="100"
      viewBox="0 0 50 50"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 23.800781 14.300781 C 23.500781 14.300781 23.400391 14.499219 23.400391 14.699219 L 23.400391 28.199219 C 23.400391 28.499219 23.600781 28.599609 23.800781 28.599609 L 26.099609 28.599609 C 26.399609 28.599609 26.5 28.399219 26.5 28.199219 L 26.5 14.699219 C 26.5 14.399219 26.299609 14.300781 26.099609 14.300781 L 23.800781 14.300781 z M 23.800781 32.300781 C 23.500781 32.300781 23.400391 32.499219 23.400391 32.699219 L 23.400391 35.300781 C 23.400391 35.600781 23.600781 35.699219 23.800781 35.699219 L 26.199219 35.699219 C 26.499219 35.699219 26.599609 35.500781 26.599609 35.300781 L 26.599609 32.699219 C 26.599609 32.399219 26.399219 32.300781 26.199219 32.300781 L 23.800781 32.300781 z"></path></svg>;
    break;

    case "danger":
    icon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="50" height="50" className="is-16 is-white"
      viewBox="0 0 50 50"><path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"></path></svg>;
    break;

    case "success":
    icon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="50" height="50" className="is-16 is-white"
      viewBox="0 0 50 50"><path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"></path></svg>;
    break;

    default:
      // update default case
      icon = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      width="50" height="50" className="is-16 is-white"
      viewBox="0 0 50 50"><path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 34.988281 14.988281 A 1.0001 1.0001 0 0 0 34.171875 15.439453 L 23.970703 30.476562 L 16.679688 23.710938 A 1.0001 1.0001 0 1 0 15.320312 25.177734 L 24.316406 33.525391 L 35.828125 16.560547 A 1.0001 1.0001 0 0 0 34.988281 14.988281 z"></path></svg>;
      break;
  }

  return (
    <div className="control">
      <div className="tags has-addons is-marginless">
        <div className={"tag is-" + props.type}>
          {icon}
        </div>
        <span className={"tag is-light is-" + props.type}>
          {props.children}
        </span>
      </div>
    </div>
  )
}
export default Tag;
