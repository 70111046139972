import React from "react";

import Cat from "../../assets/cat-image.jpg";
import Dog from "../../assets/dog-image.jpg";

import Female from "../../assets/female.jpg";
import Male from "../../assets/male.jpg";

const Step2 = (props) => {
	return (
		<div
			className={
				props.step === 2
					? "columns is-centered tab-content active"
					: "columns is-centered tab-content"
			}
			id="tab1-content"
		>
			<div className="column is-10">
				<div className="columns is-multiline">
					{/* <div className="column is-8">
								<div className="columns is-mobile">
									<label className="radio radio-image column is-6">
										<div className="card">
										<input type="radio" name="type" id="dog" />
											<div className="card-content has-text-centered">
												<img src={Dog} alt="Dog illustration" />
										    <p>Dog</p>
											</div>
										</div>
								  </label>

									<label className="radio radio-image column is-6">
										<div className="card">
											<input type="radio" name="type" id="cat" />
											<div className="card-content has-text-centered">
												<img src={Cat} alt="Cat illustration" />
										    <p>Cat</p>
											</div>
										</div>
								  </label>
								</div>
							</div>
							<div className="column is-4"></div> */}

					<div className="column is-8">
						<div className="columns is-mobile">
							<label className="radio radio-image column is-6">
								<div className="card">
									<input
										type="radio"
										name="gender"
										id="female"
										value="female"
										checked={props.male === false}
										onChange={(e) => props.setMale(false)}
									/>
									<div className="card-content has-text-centered">
										<img src={Female} alt="Female symbol" />
										<p>Female</p>
									</div>
								</div>
							</label>

							<label className="radio radio-image column is-6">
								<div className="card">
									<input
										type="radio"
										name="gender"
										id="male"
										value="male"
										checked={props.male === true}
										onChange={(e) => props.setMale(true)}
									/>
									<div className="card-content has-text-centered">
										<img src={Male} alt="Male symbol" />
										<p>Male</p>
									</div>
								</div>
							</label>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<div className="columns is-mobile">
							<label className="radio radio-image column is-6">
								<div className="card">
									<input
										type="radio"
										name="status"
										id="spayed"
										checked={props.spayed === true}
										onChange={(e) => {props.setSpayed(true); props.setPrice(props.domain.tag_price_spayed_neutered)}}
									/>
									<div className="card-content has-text-centered">
										<p>Spayed / Neutered</p>
									</div>
								</div>
							</label>

							<label className="radio radio-image column is-6">
								<div className="card" style={{ height: "100%" }}>
									<input
										type="radio"
										name="status"
										id="unaltered"
										checked={props.spayed === false}
										onChange={(e) => {props.setSpayed(false); props.setPrice(props.domain.tag_price_male_female_dogs)}}
									/>
									<div className="card-content has-text-centered">
										<p>Unaltered</p>
									</div>
								</div>
							</label>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<div className="field">
							<label className="label">Pet name</label>
							<div className="control">
								<input
									className="input"
									type="text"
									placeholder="Max"
									id="petName"
								/>
							</div>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<div className="field">
							<label className="label">Pet date of birth</label>
							<div className="control">
								<input
									className="input"
									type="date"
									id="petDob"
									placeholder="26 July 2019"
								/>
							</div>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<label className="label">Est. adult weight</label>
						<div className="field has-addons">
							<p className="control">
								<input
									className="input"
									type="number"
									id="petWeight"
									placeholder="50"
									defaultValue="50"
								/>
							</p>
							<p className="control">
								<button className="button is-static">LBS</button>
							</p>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<div className="field">
							<label className="label">Color/Identifying marks</label>
							<div className="control">
								<input
									className="input"
									type="text"
									id="petColor"
									placeholder="Brown"
								/>
							</div>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<div className="field">
							<label className="label">Breed</label>
							<div className="control">
								<input
									className="input"
									type="text"
									id="petBreed"
									placeholder="Dachshund"
								/>
							</div>
						</div>
					</div>
					<div className="column is-4"></div>

					<div className="column is-8">
						<div className="field">
							<label className="label">Hair</label>
							<div className="control">
								<div className="select is-fullwidth is-primary">
									<select id="petHair" defaultValue="Short">
										<option value="Short">Short</option>
										<option value="Long">Long</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="column is-4"></div>
				</div>
			</div>
		</div>
	);
};

export default Step2;
